import Card from '@components/card/card';
import { useTranslation } from '@lib/useTypedTranslation';
import * as React from 'react';

export const DevicesCard = () => {
  const { t } = useTranslation('maps');

  return <Card className='devices-card'>
    <div className='devices-card_header'>
      <h3>{t("DEVICES_HEADING")}</h3>
    </div>
    <p>{t("CLUSTER_PROMPT")}</p>
  </Card>;
};
