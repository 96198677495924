import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';

import { exportDevicesUtilizationChartAction, IDevicesUtilization, IPerformanceChartParams } from '../../../../services/core/performance';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { formatTooltipDate, getFormattedTitle, getTitle } from './lib';
import { drawXAxisLabels } from '../../../../components/chart/lib';
import { getUserTimeZone } from '../../../../lib/timeZone';
import { Filter } from '../../../../components/timePeriodAndFilterPicker/useFilterPicker';

import './charts.css';
import { StackedColumnsDateChart } from '@components/chart/stackedColumnsDateChart';
import { DeviceInfoContext } from '../../../../containers/core/device';

interface IProps {
  data: IDevicesUtilization[],
  filter: Filter,
  days: number,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function CorePerformanceDevicesUtilization(props: IProps) {
  const { data, filter, days } = props;
  const timeZoneOffset = useContext(DeviceInfoContext).timeZoneOffset || 0;
  const exportChartData = useWorldAction(exportDevicesUtilizationChartAction);
  const { t } = useTranslation('performance');
  const title = getTitle(filter, days, t, 'DEVICES_USAGE', true);

  const formattedTitle = getFormattedTitle(title);

  const onExportClicked = async (): Promise<string> => {
    const timeZone = getUserTimeZone();
    const params: IPerformanceChartParams = {
      days,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone
    };
    return exportChartData(params);
  };

  return (
    <div className = 'core-performance-chart-section'>
      <StackedColumnsDateChart<any>
        data={data.map((data) => ({
          deviceCount: data.deviceCount,
          date: parseInt(data.date)
        }))}
        className={`core-performance-chart-devices-utilization`}
        export={{
          filename: `${formattedTitle}.${moment.utc().toISOString()}.csv`,
          onExportClicked: () => onExportClicked(),
          csv: true
        }}
        series={[{
          dataKey: 'deviceCount',
          description: t('DEVICES_USED'),
          visible: true,
          colour: '#0fa336',
        }]}
        title={title}
        days={props.days}
        dateAxisEndDuration={{ amount: '1', unit: 'day' }}
        xAxisType={'date'}
        yAxisType={'value'}
        formatTooltipDate={formatTooltipDate}
        cypressId={'corePerformanceDevicesUtilization'}
        hasLegend={true}
        drawXAxis={(_, xAxis) => drawXAxisLabels(xAxis as am4charts.DateAxis, days, timeZoneOffset)}
        onMount={props.onMount}
        useAxisTooltip
        bottomBorder={true}
      />
    </div>
  );
}
