import * as React from "react";
import { ClusterMap } from "./map";
import { DevicesCard } from "./devicesCard";

import './clusterMap.css';
import { DeviceStatsResponse } from "services/core/maps";
import { RequestInitWithRetry } from "@lib/request";

export interface ClusterMapContainerProps<TData> {
  deviceStats: DeviceStatsResponse,
  fetchMapData: (boundingLatLong: string, geotilePrecision: number) => (options: RequestInitWithRetry) => Promise<TData[]>
}
export const ClusterMapContainer = (props: ClusterMapContainerProps<any>) => {
  return <div className="cluster-map-container">
    <ClusterMap deviceStats={props.deviceStats} fetchMapData={props.fetchMapData} />
    <DevicesCard />
  </div>;
};
