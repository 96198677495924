import * as React from "react";
import * as GeoTile from "geotile";

import { LatLng, latLng, latLngBounds, Map as LeafletMap } from "leaflet";
import { UseRequestReturn } from "@lib/useRequest";
import { GeoBoundsResponse } from "services/core/maps";
import { MapProps, Map } from "react-leaflet";
import { useLongPress } from "@lib/useLongPress";

export const MIN_LATITUDE = -85.05112877980659;
export const MAX_LATITUDE = 85.05112877980659;

export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 179.9999999999999;

/** a factor multiplied by the original bounds to determine the additional padded area */
const BOUNDS_FACTOR_PADDING = 0.25;

export const getGeotilePrecisionFromMapZoom = (mapZoom: number): number => {
  return Math.floor(mapZoom || 0) + 2;
};

const ZOOM_TIMEOUT_MS = 50;

export const useZoom = (mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>, zoomIncrement: number) => {
  const smoothZoomIn = useLongPress(() => {
    mapRef.current?.leafletElement.zoomIn(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const smoothZoomOut = useLongPress(() => {
    mapRef.current?.leafletElement.zoomOut(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const zoomInDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMaxZoom();
  const zoomOutDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMinZoom();

  return {
    smoothZoomIn,
    smoothZoomOut,
    zoomInDisabled,
    zoomOutDisabled
  };
};

export const getGeotileMapBounds = (sw: LatLng, ne: LatLng, precision: number) => {
  const swTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.max(sw.lat, MIN_LATITUDE), Math.max(sw.lng, MIN_LONGITUDE), precision));
  const neTile = GeoTile.tileFromTileId(GeoTile.tileIdFromLatLong(Math.min(ne.lat, MAX_LATITUDE), Math.min(ne.lng, MAX_LONGITUDE), precision));

  return latLngBounds(latLng(swTile.latitudeSouth, swTile.longitudeWest), latLng(neTile.latitudeNorth, neTile.longitudeEast));
};

export const useInitialBoundsSet = (
  initialBoundsRequest: UseRequestReturn<GeoBoundsResponse>,
  mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>,
) => {
  const [initialBoundsMinZoom, setInitialBoundsMinZoom] = React.useState(null);

  const setInitialBounds = React.useCallback(() => {
    if (initialBoundsRequest.data) {
      const { geoBounds: { bottom, left, top, right } } = initialBoundsRequest.data;

      const apiBoundsSw = latLng(Math.max(bottom, MIN_LATITUDE), Math.max(left, MIN_LONGITUDE));
      const apiBoundsNe = latLng(Math.min(top, MAX_LATITUDE), Math.min(right, MAX_LONGITUDE));

      mapRef.current.leafletElement.setMinZoom(0);

      const apiBounds = latLngBounds(apiBoundsSw, apiBoundsNe);
      const apiBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(apiBounds);

      const currentPrecision = getGeotilePrecisionFromMapZoom(apiBoundsZoom);
      const clientBounds = getGeotileMapBounds(apiBoundsSw, apiBoundsNe, currentPrecision);

      const paddedBounds = clientBounds.pad(BOUNDS_FACTOR_PADDING);
      mapRef.current.leafletElement.setMaxBounds(paddedBounds);

      const paddedBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(paddedBounds);
      mapRef.current.leafletElement.setMinZoom(Math.max(paddedBoundsZoom - 1, 0));

      mapRef.current.leafletElement.fitBounds(paddedBounds);

      setInitialBoundsMinZoom(paddedBoundsZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsRequest.data]);

  React.useEffect(() => {
    setInitialBounds();
  }, [setInitialBounds]);

  React.useEffect(() => {
    if (initialBoundsMinZoom) {
      mapRef.current.leafletElement.setMinZoom(initialBoundsMinZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsMinZoom]);

  return {
    resetInitialBounds: () => {
      setInitialBoundsMinZoom(null);
      setInitialBounds();
    },
    mapTilesShown: typeof initialBoundsMinZoom === 'number'
  };
};
