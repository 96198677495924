import React, { ReactNode, ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { range } from 'lodash';
import classNames from 'classnames';

import { Button } from '../controls/button';

import './pagination.css';
import { ButtonColours } from '../../containers/app/themes';
import styled from 'styled-components';

const getPageFromOffset = (offset: number, limit: number) => {
  return Math.floor(offset / limit) + 1;
};

const getOffsetFromPage = (page: number, limit: number) => {
  return (page - 1) * limit;
};

const getPageRage = (first: number, last: number, limit: number): [number, number][] => {
  return range(first, last + 1).map(p => [p, getOffsetFromPage(p, limit)]);
};

const getLinkPages = (currentPage: number, lastPage: number, limit: number): [number, number][] => {
  if (lastPage <= 5) {
    return getPageRage(1, lastPage, limit);
  }
  const isStart = currentPage < 4;
  const isEnd = currentPage > lastPage - 3;
  const first: [number, number] = [1, 0];
  const last: [number, number] = [lastPage, getOffsetFromPage(lastPage, limit)];
  if (isStart) {
    return [...getPageRage(1, 4, limit), last];
  }
  if (isEnd) {
    return [first, ...getPageRage(lastPage - 3, lastPage, limit)];
  }
  return [first, ...getPageRage(currentPage - 1, currentPage + 1, limit), last];
};

interface IProps {
  total: number,
  offset: number,
  limit: number,
  pageSizes?: number[],
  onNewPage: (newOffset: number) => void,
  onNewLimit: (newLimit: number) => void,
  children?: ReactNode
}

const StyledPaginationSelectButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 28px;
    height: 28px;
  `;

StyledPaginationSelectButton.displayName = 'PaginationSelectButton';

const StyledPaginationPrev = styled(StyledPaginationSelectButton)`
  margin-right: 8px;
  margin-left: 0;
`;

StyledPaginationPrev.displayName = 'PaginationPrev';

const StyledPaginationNext = styled(StyledPaginationSelectButton)`
  margin-left: 8px;
  margin-right: 0;
`;

StyledPaginationNext.displayName = 'PaginationNext';

const StyledPageInput = styled.input`
  width: 60px;
  border: 1px solid #c2d1e0;
  border-radius: 3px;
  padding: 0.125rem;
`;

StyledPageInput.displayName = 'PageInput';

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
`;

const StyledNavIcon = styled.i<{ disabled: boolean }>`
  ${({ disabled }) => disabled ? 'color: #d8d8d8' : 'color: inherit'}
`;

StyledNavIcon.displayName = 'NavIcon';

const StyledPageLinkButton = styled.button`
  border: none;
  background: none;
  &:active && :hover {
    outline: none;
    border: none;
  }
`;

StyledPageLinkButton.displayName = 'PageLinkButton';

export const defaultPageSizes = [10, 20, 30, 40, 50];
export const totalLimit = 10000;

export function getListTotal(totalItems: number) {
  return Math.min(totalItems, totalLimit);
}

export function PaginationBar(props: IProps) {
  const { total, offset, limit, onNewPage, onNewLimit, pageSizes = defaultPageSizes, children } = props;
  const [goToPage, setGoToPage] = useState('');
  const { t } = useTranslation();
  const listTotal = getListTotal(total);

  const sortedPageSizes = [...new Set([...pageSizes, limit])].sort();

  const currentPage = getPageFromOffset(offset, limit);
  const lastPageOffset = Math.max(0, Math.floor((listTotal - 1) / limit) * limit);
  const lastPage = getPageFromOffset(lastPageOffset, limit);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numbersRe = /^[0-9]+$/;
    if (e.target.value === '' || numbersRe.test(e.target.value)) {
      setGoToPage(e.target.value);
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onGoToPage();
    }
  };
  const onGoToPage = () => {
    if (!goToPage) { return; }
    const page = Math.max(1, Math.min(Number(goToPage), lastPage));
    onNewPage(getOffsetFromPage(page, limit));
  };

  const select = (
    <div className="pagination-bar-component__page-size">
      <span className="pagination-bar-component__page-size__text">{t('PER_PAGE')}:</span>
      <select aria-label="pagination page size selection" className="pagination-bar-component__page-size__select" value={limit} onChange={(e) => onNewLimit(Number(e.target.value))} >
        {sortedPageSizes.map(s => <option className="pagination-bar-component__page-size__option" value={s} key={s}>{s}</option>)}
      </select>
    </div>
  );

  const message = children && (
    <div className="pagination-bar-component__message">
      {children}
    </div>
  );


  const goTo = (
    <div className="pagination-bar-component__go-to-page">
      <span className="pagination-bar-component__go-to-page__text">{t('JUMP_TO_PAGE')}:</span>
      <StyledForm onSubmit={e => { e.preventDefault(); }}>
        <StyledPageInput
          autoFocus={Boolean(goToPage)}
          type="text"
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={goToPage}
          disabled={!total}
          data-id='pagination-goTo-input'
          aria-label="navigation jump to page number"/>
        <StyledPaginationSelectButton
          colour={ButtonColours.grey}
          onClick={onGoToPage}
          disabled={!goToPage}
          text={t('GO')}
          dataId='pagination-goTo-button'
          ariaLabel="navigation jump to page"
        />
      </StyledForm>
    </div>
  );

  const links = getLinkPages(currentPage, lastPage, limit)
    .map(([page, offset], i, pages) => {
      const ellipsis = page > 1 && (page - 1 !== pages[i - 1][0]) && <span aria-label="navigation ellipsis abbreviation" className="pagination-bar-component__page-link__ellipsis">…</span>;
      const isCurrent = page === currentPage;
      const className = classNames(
        'pagination-bar-component__page-link',
        { 'pagination-bar-component__page-link--active': isCurrent }
      );
      return (
        <div className={className} key={`page-link-${page}`}>
          {ellipsis}
          <StyledPageLinkButton aria-label={`navigation jump to page ${page}`} className="pagination-bar-component__page-link__number" onClick={() => !isCurrent && onNewPage(offset)}>
            {page}
          </StyledPageLinkButton>
        </div>
      );
    });

  const navigation = (
    <div className="pagination-bar-component__navigation">
      <StyledPaginationPrev
        colour={ButtonColours.grey}
        iconStyle="fa fa-chevron-left"
        disabled={currentPage === 1}
        onClick={() => onNewPage(Math.max(offset - limit, 0))}
        iconComponent={<StyledNavIcon disabled={currentPage === 1} className="fa fa-chevron-left"/>}
        dataId='pagination-pagePrev'
        ariaLabel="navigation previous page"
      />
      <div className="pagination-bar-component__page-links">
        {Boolean(total) && links}
      </div>
      <StyledPaginationNext
        colour={ButtonColours.grey}
        iconStyle="fa fa-chevron-right"
        disabled={currentPage === lastPage}
        onClick={() => onNewPage(Math.min(offset + limit, lastPageOffset))}
        iconComponent={<StyledNavIcon disabled={currentPage === lastPage} className="fa fa-chevron-right"/>}
        dataId='pagination-pageNext'
        ariaLabel="navigation next page"
      />
    </div>
  );

  return (
    <div className="pagination-bar-component">
      <div className="pagination-bar-component__left">
        {message}
        {select}
      </div>
      <div className="pagination-bar-component__right">
        {goTo}
        {navigation}
      </div>
    </div>
  );
}
