import * as React from 'react';
import Control from 'react-leaflet-control';
import { Map, TileLayer } from 'react-leaflet';

import { useTranslation } from '@lib/useTypedTranslation';
import { Trans } from '@components/i18n/typedTrans';

import { DeviceStatsResponse, getDeviceGeoBounds } from '../../../services/core/maps';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { NoSelectionOverlay } from '../../card/noSelectionOverlay';
import { useInitialBoundsSet, useZoom } from '../utils';

import '../sharedMap.css';
import { RequestInitWithRetry } from '@lib/request';
import { LatLngBounds } from 'leaflet';
import { useDebounce } from '@lib/useDebounce';

export interface ClusterMapProps<TData> {
  deviceStats: DeviceStatsResponse,
  fetchMapData: (boundingLatLong: string, geotilePrecision: number) => (options: RequestInitWithRetry) => Promise<TData[]>
}

const fixMapBoundsGetter = () => {
  const getMapBounds = getDeviceGeoBounds({});
  return getMapBounds;
};

export const ClusterMap = ({
  deviceStats,
  fetchMapData
}: ClusterMapProps<any>) => {
  const mapRef = React.useRef<Map>();
  const { t } = useTranslation('translation');

  // INTERACTION STATE
  const [bounds, setBounds] = React.useState<LatLngBounds>();
  const [geotilePrecision, setGeotilePrecision] = React.useState<number>();

  const { addDebounceEvent, isDebouncing } = useDebounce(500);

  const getFetch = React.useMemo(() => {
    return fixMapBoundsGetter;
  }, []);

  const initialBoundsRequest = useWorldRequest(getFetch);

  const { resetInitialBounds, mapTilesShown } = useInitialBoundsSet(
    initialBoundsRequest,
    mapRef,
  );

  // Map Data
  const fetchMapDataRequest = React.useCallback(() => {
    // no fetcher returned if no bounds or precision
    if (!bounds) {
      return undefined;
    }

    return fetchMapData('', geotilePrecision);
  }, [bounds, geotilePrecision, fetchMapData]);

  const mapDataRequest = useWorldRequest(fetchMapDataRequest);

  // triggers fresh map data fetching via useWorldRequest
  const prepareMapDataRequest = React.useCallback(() => {
    if (initialBoundsRequest.data) {
      const bounds = mapRef.current?.leafletElement?.getBounds();
      const zoom = mapRef.current?.leafletElement?.getZoom() || 0;
      const geotilePrecision = zoom + 3;

      setBounds(bounds);
      setGeotilePrecision(geotilePrecision);
    }
  }, [initialBoundsRequest.data]);

  const handleChange = React.useCallback(() => {
    addDebounceEvent(prepareMapDataRequest);
  }, [addDebounceEvent, prepareMapDataRequest]);

  const isLoading = isDebouncing || mapDataRequest.loading || initialBoundsRequest.loading;

  const { smoothZoomIn, smoothZoomOut, zoomInDisabled, zoomOutDisabled } = useZoom(mapRef, 1);

  return <React.Fragment>
    <div className="cluster-map shared-map" data-id="cluster-map">
      {/* cannot be with other leaflet controls: https://github.com/LiveBy/react-leaflet-control/issues/16 */}
      <div className="mapZoomButtonContainer">
        <i
          className="mapZoomButton fas fa-plus"
          onPointerDown={!zoomInDisabled ? smoothZoomIn.pointerDown : undefined}
          onPointerUp={!zoomInDisabled ? smoothZoomIn.pointerUp : undefined}
          aria-disabled={zoomInDisabled}
          aria-label="Zoom in"
          title="Zoom in"
        />
        <i
          className="mapZoomButton fas fa-minus"
          onPointerDown={!zoomOutDisabled ? smoothZoomOut.pointerDown : undefined}
          onPointerUp={!zoomOutDisabled ? smoothZoomOut.pointerUp : undefined}
          aria-disabled={zoomOutDisabled}
          aria-label="Zoom out"
          title="Zoom out"
        />
      </div>
      <NoSelectionOverlay
        show={!isLoading && (initialBoundsRequest.data === null)}
        noSelectionText={t('NO_DATA_AVAILABLE')}
      />
      <Map
        ref={ref => { if (ref) mapRef.current = ref; }}
        zoomControl={false}
        bounds={[[-90, -180], [90, 180]]}
        onmoveend={handleChange}
        onzoomend={handleChange}
        onresize={resetInitialBounds}
        maxZoom={18}
        zoomSnap={1}
      >
        <Control position="topright">
          <i className="mapResetButton fas fa-undo-alt" aria-label="Reset map" onClick={resetInitialBounds} />
        </Control>
        <Control position="bottomleft">
          <div className="bottom-left-container">
            {isLoading && <div className='loading-indicator'>
              <i className="fas fa-spinner fa-spin" />
              {t('LOADING')}
            </div>}
            {deviceStats.totalCount > deviceStats.withLocationCount && <div className="device-stats" data-id="device-stats">
              <Trans ns='maps' i18nKey={'LOCATION_COLLECTION_INFO'} values={deviceStats}><strong>TOTAL</strong> devices match the filter, <strong>WITHINFO</strong> have location information and can be displayed on the map</Trans>
            </div>}
          </div>
        </Control>
        {mapTilesShown && <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          noWrap
        />}
      </Map>
    </div>
  </React.Fragment>;
};
